html, body, #root {
    min-height: 100vh;
    width: 100%;
    height: 100% !important;
}

html, body {
    overflow-y: visible !important;
    /*overflow-x: hidden;*/
    padding-right: 0 !important;
}

.myDashContainer {
    height: 100%;
}

.progressBar img {
    margin-top: 150px !important;
    height: 400px !important;
    max-height: 500px !important;
}

.myDashContainer img {
    margin-top: 0;
}

.navbar-brand img {
    width: 178px;
    height: 100%;
}

.icon img {
    width: 25px;
    height: 25px;
}

.hamburger img {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.user-actions img {
    width: 20px;
    height: 30px;
}

/*.dashboard-navbar {*/
/*    background: #fff;*/
/*    !*height: 12vh !important;*!*/
/*    border-bottom: rgba(130, 130, 130, .15) solid 1px;*/
/*}*/

.my-navbar {
    background: #fff;
    border-bottom: rgba(130, 130, 130, .15) solid 1px;
    width: 100% !important;
    height: 13vh;
}

.header-dropdown img {
    width: 17px !important;
    margin-left: 5px;
}

.user {
    width: 175px;
}

.user-header {
    color: #960c0c;
}

.dropdown-icon {
    width: 20px;
    height: 20px;
}

.user-corner {
    width: 125px;
    cursor: pointer;
}

.user-image .image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ccc;
}

.form-container {
    overflow-x: hidden;
}

.MuiMenuItem-root {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    /*background: red !important;*/
}

.MuiMenuItem-root:nth-child(2) {
    border-top: 2px solid rgba(229, 229, 229, 0.5);
    /*border-bottom: 2px solid rgba(229, 229, 229, 0.5);*/
}

.MuiListItemIcon-root {
    min-width: 36px !important;
    display: flex;
    align-items: center;
}

notification.MuiMenuItem-root .MuiTypography-root {
    word-wrap: break-word;
}

div a {
    color: black;
    text-decoration: none !important;
}

div a:hover {
    color: black;
}

.MuiListItemText-root {
    margin: 0 !important;
}

.MuiListItem-gutters {
    padding-right: 65px !important;
}

.image img {
    width: 40px;
    height: 40px;
}

.user-left {
    width: 72%;
}

.user-right {
    width: 28%;
    background: #FFF;
    margin-bottom: 2px;
    border-left: 1px solid rgba(130, 130, 130, 0.15);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /*padding-left: 70px;*/
}

.horizontal-divider {
    height: 12%;
    width: 100%;
    border-bottom: 1px solid rgba(130, 130, 130, 0.15);
}

.notif-icon {
    cursor: pointer;
}

.menu {
    font-weight: bold;
}

.dashboard-holder {
    padding-top: 140px;
    padding-bottom: 50px;
    /*height: 100%;*/
}

.thumb-active {
    background-color: #00D689 !important;
}

/*.MuiSlider-markActive{*/
/*    background-color: #00D689 !important;*/
/*}*/


.dashboard-container {
    background: #FFFFFF;
    box-shadow: 0 15px 30px rgba(130, 130, 130, 0.15);
    border-radius: 10px;
    width: 90%;
    overflow: hidden;
}

.progressBar {
    flex: 1;
    transform: translateX(25px);
    padding-top: 166px;
    /*transform: rotate(180deg);*/
}

.dashboard-inner-container {
    width: 100%;
}

.greeting h3 {
    /*font-family: "IBM Plex Sans";*/
    color: #B1B1B1;
    font-weight: bold;
}

.dashboard-content {
    padding: 60px 70px 100px 40px;
    flex: 20;
}

.questionnaire-row,
.test-row,
.interview-row,
.admission-row {
    padding: 30px 20px 15px;
    border-radius: 6px;
    background: #FFF2C7;
    margin-bottom: 40px;
}

.questionnaire-icon, .test-icon, .interview-icon {
    width: 15%;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.questionnaire-icon .icon {
    background: #00D689;
}

.test-icon .icon {
    background: #1C8AEC;
}

.interview-icon .icon {
    background: #A07DD0;
}

.payment-icon .icon {
    background: #FF7E1D;
}

.enrollment-icon .icon {
    background: #FFB403;
}

.text-and-btn {
    width: 100%;
    margin-left: 10px;
}

.text {
    width: 50%;
}

.text-and-btn h6 {
    font-weight: bold;
    color: #2B1C1C;
}

.dashboard-btn {
    padding-right: 20px;

}

.text-and-btn button {
    font-size: 14px !important;
    height: 33px !important;
    min-width: 110px !important;
    font-weight: bold;
    border-radius: 8px;
}

.btn-arrow img {
    width: 20px !important;
    height: 15px !important;
    margin-left: 10px;
}

/*.test-row button, .interview-row button {*/
/*    background: #2B1C1C !important;*/
/*    border-color: #2B1C1C !important;*/
/*}*/

.apx {
    font-weight: lighter;
    font-size: 8px;
}

.steps-and-time {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 3px;
    width: 160px;
}

.steps-and-time p {
    margin: 0 !important;
}

.side-bar {
    height: 88%;
}

.close-btn {
    display: none;
}

.applicant-details {
    height: 60%;
    /*margin-top: -50px;*/
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(130, 130, 130, 0.15);
}

.applicant-id {
    color: rgba(43, 28, 28, 0.5);
    font-size: 14px;
}

.applicant-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ccc;
    /* background: #FFF2C7; */
}


.pen {
    padding-left: 60px;
}

.applicant-name h4 {
    color: rgba(43, 28, 28, 0.85);
    font-size: 1.3rem;
    font-weight: bold;
}

.applicant-status {
    background: #FFF0CE;
    border: 1px solid #FFB403;
    color: #960c0c;
    border-radius: 2px;
    font-size: 14px;
}

.applicant-status h5 {
    font-size: inherit;
    font-weight: bold;
}

.complaint-icon, .log-out-icon, .comment-icon {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 15px;
}

.comment-icon {
    background: #c8e7f8;
}

.comment-icon + span {
    color: #147DB9;
    font-weight: 600;
}

.complaint-icon {
    background: #FFDDD9;
}

.complaint-icon + span {
    color: #FF7E1D;
    font-weight: 600;
}

.log-out-icon {
    background: rgba(67, 18, 136, 0.2);
}

.log-out-icon + span {
    color: #431288;
    font-weight: 600;
}

.user-image {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.questionnaire-btn {
    width: 140px;
    height: 76%;
    border-radius: 6px;
}

.audio {
    border: 1px solid #FF7E1D;
    padding: 15px 10px;
    border-radius: 8px;
}

.audio img {
    width: 25px !important;
    height: 25px !important;
}


@media only screen and (max-width: 991px) {
    .user-corner {
        display: none !important;
    }

    .hamburger-button {
        display: block !important;
    }

    .user {
        width: initial;
        margin-right: -30px;
    }

    .myDashContainer {
        position: relative;
        overflow-x: hidden;
    }


    .horizontal-divider {
        display: none;
    }

    /*.dashboard-holder {*/
    /*    position: relative;*/
    /*    overflow-x: hidden;*/
    /*}*/
    .dashboard-holder::-webkit-scrollbar {
        display: none;
    }

    .questionnaire-row,
    .test-row,
    .interview-row,
    .admission-row {
        flex-direction: column;
    }

    .dashboard-btn {
        padding-right: 0;
    }

    .user-left {
        width: 100%;
    }

    .text-and-btn {
        margin-top: 18px;
        margin-left: 0;
    }

    .steps-and-time {
        flex-direction: column !important;
    }

    .steps + span + p {
        margin-top: 5px !important;
    }

    .steps + span {
        display: none;
    }

    .text-and-btn h6 {
        font-size: 26px !important;
        max-width: 150px;
        width: 150px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .text.text-nowrap {
        white-space: initial !important;
    }

    .questionnaire-btn {
        height: 45px !important;
        min-width: 0px;
    }

    .user-right {
        position: fixed;
        transform: translateX(100%);
        top: 0;
        right: 0;
        width: 80% !important;
        min-height: 100vh !important;
        z-index: 10000;
        transition: all 0.8s ease-in-out;
    }

    .user-actions {
        justify-content: center !important;
    }

    .user-right-in-view {
        transform: translateX(0);
    }

    .side-bar-pause {
        overflow-y: hidden;
    }

    .close-btn {
        display: block;
    }

    .progressBar img {
        height: 500px !important;
        max-height: 500px;
        width: 24px !important;
        max-width: 24px !important;
    }
}

@media only screen and (max-width: 767px) {
    .desktop-progress-bar {
        display: none;
    }

    .progressBar {
        display: none;
    }

    .dashboard-container {
        padding: 30px 10px;
    }

    .dashboard-content {
        padding: 0 !important;
    }
}


@media only screen and (max-width: 600px) {

    .dashboard-container {
        width: 100% !important;
    }

    .greeting h3 {
        margin: auto;
        text-align: center;
        font-size: initial;
    }

    .questionnaire-row,
    .test-row,
    .interview-row,
    .admission-row {
        flex-direction: column;
        margin-left: 15px;
        margin-bottom: 20px;
        display: block;
    }

    .text-and-btn h6 {
        font-size: smaller;
        margin-left: 20px;
    }

    .steps-and-time {
        margin-left: 20px;
        font-size: small;

    }

    .icon {
        margin-left: 20px;
    }

    .dashboard-btn {

        margin-left: 20px;
    }

    .pend {
        padding-bottom: 5px;
        padding-top: 5px;
    }


}

@media (min-width: 991px) {
    .dashboard-content-scrollable {
        max-height: 650px;
        overflow: scroll;
    }

    .dashboard-content-scrollable::-webkit-scrollbar {
        background: transparent !important;
        width: 9px;
        height: 20px;
    }

    .dashboard-content-scrollable::-webkit-scrollbar-thumb {
        background: rgba(43, 28, 28, 0.5);
        max-height: 20px !important;
        height: 20px !important;
        border-radius: 12px;
    }
}