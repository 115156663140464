@media screen and (min-width: 600px) {
    .vh-79 {
        padding: 30px;
        margin-top: 100px;
        /*width: 100%;*/
        /*min-height: 75vh;*/
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    .firstRad {
        position: relative;
        top: 2px !important;
    }

    .secondRad {
        position: relative;
        top: -100px !important;
    }

}

@media (max-width: 530px) {
    .agreementInput {
        width: 100% !important;
    }
}

@media screen and (max-width: 200px) {
    .vh-79 {
        padding: 30px;
        /*position: absolute;*/
        /*top:250px;*/
        margin-top: 100px;
        bottom: 0;
        background: white;
    }

    .firstRad {
        position: relative;
        top: 21px !important;
    }

    .secondRad {
        position: relative;
        top: -47px !important;
    }

}
.MuiOutlinedInput-root fieldset{
    border: 1px solid rgba(150, 12, 12, 0.5) !important;
}
.formContainers {
    overflow: hidden;
}
.multipleCheck{
    width: 10px !important;
    /*margin-right: -50px;*/
}
.MuiPopover-paper{
    max-height: 220px !important;
    padding-top: 8px !important;
    width: 240px !important;
    /*min-height: 195px !important;*/
}

.menu{
    max-height: 148px !important;
    min-height: 148px !important;
    padding-top: 8px !important;
    width: 250px !important;
}
/*.MuiOutlinedInput-root{*/
/*    height: 50px !important;*/
/*}*/
.MuiOutlinedInput-root .industryHeight{
    height: 100% !important;
}
.personalCon{
    margin: 120px auto;
}
.row-spacing{
    margin-top: 15px;
}
.health-radio{
    margin-left: 44px ;
}


.textField {
    padding-bottom: 40px !important;
}

.heat {
    height: 70px;
    background: red;
    width: 100%;
}

/*.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {*/
/*    padding-left: 6px !important;*/
/*}*/
/*.sets {*/
/*    padding: 9.5px 4px !important;*/
/*}*/
.MuiInputBase-input {
    margin: 0 !important;
}

.MuiFormControl-root {
    width: 100% !important;
}

.MuiInputBase-input {
    margin-bottom: 15px !important;
}

.MuiInputBase-input {
    margin: 0 !important;
}

.MuiAutocomplete-tag {
    /*margin: 3px;*/
    max-width: calc(100% - 6px);
    margin-bottom: 9px !important;
}

/*.MuiChip-label {*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*    padding-left: 12px;*/
/*    padding-right: 12px;*/
/*    margin-bottom: 23px;*/
/*    text-overflow: ellipsis;*/
/*}*/

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 20px 0 20px 0 !important;
    font-size: 19px;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 0 !important;
    min-width: 20px !important;
}

.MuiAutocomplete-inputFocused {
    opacity: 1;
    margin-top: 40px;
}

.MuiInputBase-fullWidth {
    width: 100%;
    min-height: 60px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 9.5px 4px;
    min-height: 20px !important;
    margin-bottom: 0 !important;
}

.newText .MuiOutlinedInput-input {
    padding: 18.5px 14px;
    margin-bottom: 6px !important;
    margin-top: 6px !important;
}

.shad {
    /*box-shadow: 0px 15px 30px rgba(130, 130, 130, 0.15);*/
    margin-top: 30px;
}

.MuiSelect-selectMenu {
    height: auto;
    padding-bottom: 23.5px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
}

.radioButton .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 0.7em;
    /* display: inline-block; */
    font-size: 1.5rem;
    /* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    flex-shrink: 0;
    user-select: none;
}

.radioText .MuiTypography-body1 {
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.PrivateSwitchBase-root-135 {
    padding: 9px 0 9px 0 !important;
}

.small, small {
    font-family: IBM Plex Sans, serif;
    font-size: 90%;
    font-weight: 400;
    margin-top: 6px;
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*font-size: 15px;*/
    /*line-height: 23px;*/
    /*display: flex;*/
    /*align-items: center;*/
    position: relative;
    top: 10px;

    color: #4e4d4d;
    width: 80%;
}

.alignRadio {
    float: right
}

.firstRad {
    position: relative;
    top: 2px !important;
}

.secondRad {
    position: relative;
    top: -40px !important;
}

.MuiFormGroup-root {
    width: 100% !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    padding-left: 6px !important;
    font-size: 15px;
    margin-top: -4px !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    padding-bottom: 30px !important;
}

.shadow-3 {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .2);
    background: white;
}

.MuiChip-root {
    color: black !important;
    background-color: #FFF2C7 !important;
}

.MuiChip-deleteIcon {
    color: red !important;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
    -webkit-appearance: listbox;
    margin-bottom: 6px !important;
    margin-top: 5px !important;
}

.MuiOutlinedInput-root {
    width: 100% !important;
}

.back {
    margin-left: 0 !important;
}

.centers {
    text-align: center !important;
    position: relative;
    left: 30px;
}

/*.sets{*/
/*    margin-bottom: 30px !important;*/
/*    border: 2px solid red;*/
/*}*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="date"] {
    position: relative;
    /*padding: 10px;*/
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="date"]:before {
    content: attr(label);
    position: absolute;
    color: #960C0C;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    pointer-events: none;
    line-height: 1.5;
    padding: 0 0.5rem;
}

input[type="date"]:focus:before,
input[type="date"]:not([value=""]):before {

    display: none;

}

.MuiAutocomplete-option[data-focus="true"] {
    background-color: rgba(192, 3, 3, 0.04) !important;
}

.PrivateSwitchBase-root-136 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-262 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-292 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-322 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-352 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-425 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-455 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-485 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-335 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-408 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-438 {
    padding: 0 0 0 9px !important;
}

.MuiRadio-root {
    color: rgba(148, 23, 23, 0.54);
    padding: 0 0 0 9px !important;
}

.vh-79 {
    padding: 30px;
    margin-top: 100px;
    /*width: 100%;*/
    /*min-height: 75vh;*/
    /*position: absolute;*/
    /*bottom: 0;*/
}

.MuiAutocomplete-option[aria-selected="true"] {
    background-color: rgba(246, 9, 9, 0.08) !important;
}

/*.MuiOutlinedInput-input {*/
/*    color: rgba(0, 0, 0, 0)!important;*/
/*    !* display: none !important; *!*/
/*}*/
.calender {
    content: url("../images/calendar1.svg") !important;
    position: absolute !important;
    top: 33px;
    right: 30px;
    /*margin-left: 80%;*/
    width: 17px !important;
    height: 20px;
    z-index: 1;


}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-bottom: 20px !important;
}

input[type="date"] {
    position: relative;
    padding-bottom: 27px;

}
.agreementText{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;

    color: #2B1C1C;
}
.agreementDiv{
    margin-right: 25px;
    margin-left:25px
}
.planText{
    font-family: IBM Plex Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-left: 17px;
    letter-spacing: 0.05em;

    color: rgba(43, 28, 28, 0.8);
}
.paymentTerms{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #2B1C1C;

}
.paymentCheck{
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 11px !important;
    line-height: 19px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.02em;

    color: #2B1C1C;


}
.cancelTerms {
    padding-left: 15px;
    padding-right: 15px;
    /*padding: 14px 25px;*/
    /*margin-left: 30px;*/
    color: #FF2E2E;
    background: #FFFFFF;
    border: 1px solid rgba(43, 28, 28, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    /* margin-bottom: 20px !important; */

}
.gender {
    margin-right: 30px !important;
}

.second-rad {
    position: relative;
    top: -10px !important;
}
.align-radio {
    float: right;

}
.stamp-div{
    width: 150px !important;
    height: 150px !important;
    margin: auto!important;
}
@media (max-width: 768px) {
    .gender{
        margin-right: 0;
        margin-bottom: 5px;
    }
    .health-radio{
        margin-left: 10px;
    }
    .align-radio{
        float: none;
        margin-top: 10px;
    }
}

/*.fixed-form-width {*/
/*    max-width: 600px;*/
/*}*/
