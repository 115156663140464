@media screen and (max-width: 600px) {
    .cover-div {
        background: white !important;
        margin: 1px 0 0 20px;
        min-height: 60vh;
        min-width: 100vw;
        max-height: 200vh;
        padding: 25px 30px 0;
    }

    .image-div {
        min-width: 480px;
        background: white;
        border-radius: 12px;
    }

    .newOptionDiv {

    }
}

@media screen and (min-width: 800px) {
    .cover-div {
        padding: 30px;
        width: 850px;
        background: white;
        align-self: center;
        margin-top: 10% !important;
    }

    .image-div {
        height: 400px;
        width: 550px;
        background: white;
        border-radius: 12px;
    }

    .newOptionDiv {

    }
}

#nextButton {
    background: #FF7E1D;
    border-radius: 6px;
    width: 100px;
    height: 35px;
    color: white;
    border: none;
}

#nextButton:hover {
    background: #FF8f8f;
    color: white;
}

.question {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /*line-height: 47px;*/
    /*display: flex;*/
    align-items: center;
    color: #960C0C;
    padding: 0;
}

.timer {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /*line-height: 27px;*/
    /*display: flex;*/
    align-items: center;
    text-align: right;
    color: #FF2E2E;
}

.options {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    /*line-height: 27px;*/
    /*display: flex;*/
    align-items: center;
    color: #2B1C1C;
}

.pagination {
    float: left !important;
}

.image-div {
    min-height: 0;
    min-width: 200px;
    background: white;
    border-radius: 12px;
}

.mover {
    height: 5px;
    /*margin-top: 12px;*/
    /*width: 10px;*/
    /*border-bottom: 3px solid gray;*/
    background: #FFB403;
}

hr {
    margin-top: 0;
    position: relative;
    top: 19px;
    width: 100px
}

.spar {
    margin-left: 10px;
    position: relative;
    top: -5px
}

.mb5s {
    margin-bottom: 8rem !important;
}

.newOptionDiv {
    margin: 0 0 35px 15px;
}

.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px !important;
    flex-direction: column;
}
