@import url(https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap);
body {
    margin: 0;
    /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
    /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
    /*  sans-serif;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*html, body, #root{*/
/*  height: 100vh;*/
/*  width: 100vw;*/
/*}*/

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width:600px){
    .top-card{
        background: white
    }
    .side-menu{
        max-width: 70px !important;
    }
    .off{
        display: none !important;
    }
    .export{
        margin-right: 0em !important;
    }
    .has-search .form-control-feedback{
        height: 1em !important;
        text-align: left !important;
        position: absolute !important;
    }
    .tops{
        margin-top: 18px;
    }
    .testing{
        margin-top: 20px;
    }
    .svg-top{
        display: none !important;
    }
    .full{
        margin: 4px !important;
    }
    select{
        display:none
    }
    .pagination{
        margin: 0em;
    }
}

table{
    margin-top: -20px !important;
}

.full{
    /*position: absolute;*/
    width: auto;
    /*height: 500px;*/
    /* max-height: 800px; */
    margin: 0px 30px;
    background-color: white;
    border-radius: 18px;
}
.top-card{
    height: 50px;
    width: 100% !important;
    padding: -4px;
    border-bottom: 1px solid lightgray;
}
.side-menu{
    padding: 80px 0 0 0;
    border-right: 1px solid lightgray;
    color: rgba(43, 28, 28, 0.5);
    font-weight: bold;
    flex: 1 1;
    max-width: 240px;
}
.profile-menu{
    /*padding: 90px 0 0 0;*/
    /*border-right: 1px solid lightgray;*/
    color: rgba(43, 28, 28, 0.5);
    font-weight: bold;
    flex: 1 1;
    /*margin-left: -1190px !important;*/
    /*max-width: 240px;*/
}
.card-class{
    border-left: 1px solid lightgray;
}
.student-id{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    padding: 8px;
    font-size: 14px;
    /*line-height: 18px;*/
    /*display: flex;*/
    align-items: center;
    /*text-align: center;*/
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(43, 28, 28, 0.5);
}
/* .paginate{

} */
.pic-div{
    background-color: #fceba7;
    height:120px;
    width: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    /*border: 3px solid blue;*/
}
.status{
    background: #FFF0CE;
    border: 2px solid #FFB403;
    box-sizing: border-box;
    border-radius: 6px;
    /*padding: 12px;*/
    margin: auto;
    width: 180px ;
    height: 30px;
    color: #960C0C;
}

.invite-box{
    width: 25px;
    height: 25px;
    background: #FFDDD9;
    position: relative;
    cursor: pointer;
    border-radius: 6px;
    /*top: 10px;*/
    /*right: 10px;*/
    /*margin-top: -60px;*/
}
.comment-box{
    background: rgba(67, 18, 136, 0.2);
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    /*top: 10px;*/
    /*right: 10px;*/

}
.decision-box{
    background: #C3F7E8;;
    width: 25px;
    height: 25px;
    position: relative;
   border-radius: 6px;
    cursor: pointer;
}

.invite{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    align-items: center;
    letter-spacing: 0.02em;
    color: #FF7E1D;
    margin-top: 80px;
    cursor: pointer;
}
.add-comments{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.02em;
    color: #431288;
    margin-top: 40px;
    cursor: pointer;
}
.make-decision{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.02em;
    color: #00A998;
    margin-top: 40px;
    cursor: pointer;

}
.route-div{
    padding: 15px;
    /*background-color: #00A998;*/
}

.summary-first-div{
    background-color: #fceba7;
    height: 180px;
    border-radius: 18px;
}
.summary-second-div{
    border-radius: 18px;
    height: 330px;
    /*background-color: #FF7E1D;*/
}
.summary-second-div1{
    border-radius: 18px;
    min-width: 300px;
    min-height: 330px;
    background-color: #fceba7;
    margin-left: -10px;
}
.range-form {
    background: blue;
    width:250px;
}
.app-status{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.05em;
}

div a{
    color: gray;
}

/*div a:active{*/
/*    color: #431288;*/
/*    border-right: 3px solid #431288;*/
/*}*/
.side-active:hover {
    border-right: 5px solid #431288;
}


.active-indicator {
    width: 5px;
    background: white;
}

.active-indicator-fill {
    background: #431288;
}

#searchInput{
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHQSURBVHgBnZS/TsJQFMbPaTXWGHFSQ3pHBohEJ/EBdHOSzQF8AGU3voDuhMRV2WFz8wFk02BgYCwSHEtiaqC9nlNu8QYBq1/S3Pb++fWcr+cUYUp7QthDxDNDyiMJYKvpFl1tE6D+4jhPMEOoP2Rt+0oSBBarvgRQfnac7kzQjhA3NJxEm+mqfVpWu9PpuNlkMiNN85A251WUXYIVdFgIygpRog0XvAF9/7zZ67VmhcJpjxArIGWGDjaajlOI1kxeDAAqIdX3i/MgrL7rDja2th6WR6NjenFmO5F4e3fdcL8xAihF6SyCROJUaSirx8gKMAAxre5rEFOeZT0ioktR5VKpVGIMonz55tVxGnFBHBVBQqOt4dAeg/4plHLAo/T99RCEisymw1+kLOESiUBhSj7VSFzGrhAHUkr2pqvMByP4NrkYNyo6c82jKeVdNGewyRwVmZegUqj8BqM2uqVo9im1/sfqan0CCskAl8qrDMGqVOn5WenQfDVAPCXIEgaBseZ569H6pNf08tfOhwVKNWMrT1h9+mIGNfcmaD2HP0IXgquVmzOnz3PEctzI9xwJfZyq3sAIc8QVu+J5aeq/gWma7vRvgzPQYXNBcaTB4AtM7cZJTRZfjwAAAABJRU5ErkJggg==);
}

.tester{
    display: none;
}
.testing{
    display: block;
}

.div-active {
      border-right: 5px solid #431288;
  }
.summary-head{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    margin-bottom: 20px;

    color: #960C0C;


}

div .my-label{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;

    color: #2B1C1C;
}

.has-search .form-control {
    padding-left: 1.6rem;
    background: none !important;
    border: 0;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    /* width: 2.375rem; */
    height: 2.375rem;
    /* line-height: 2.375rem; */
    margin-top: 2px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.searchOut{
    outline: none;
    /* border: none !important; */
    border-bottom: 1px solid rgba(43, 28, 28, 0.5) !important;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: none;
    box-shadow: none !important;
    width:180px;
    position: relative;
    bottom: 10px;
}

.off{
    color: rgba(43, 28, 28, 0.5)
}

.svgs{
    width: 75px;
}

.trContent{
    font-family: IBM Plex Sans;
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 23px;
/* display: flex; */
align-items: center;
letter-spacing: 0.02em;
height: 50px;
border-top: 1px solid lightgray;
color:rgba(43, 28, 28, 0.85);
}
/* th{
     padding-right: 100px; 
} */
td{
    text-transform: capitalize;
}

.export {
    cursor: pointer;
    color: #00A998;
    background: none;
    border:none;
    font-weight: 600;
    font-size: 14px;
    margin-right: -138px;
    margin-top: -18px;
    margin-left: -65px;
    z-index: 2;
    width: 85px;
}

.pagination {
    float: right;
    margin-right: 120px;
  }
  
  .pagination a {
    color: black;
    float: left;
    border:none;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    margin-right: 15px;
    margin-bottom: 10px;
      background: rgba(43, 28, 28, 0.05);
      border-radius: 4px;
  }

  .pagination a{
    color: black;
    float: left;
    border:none;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    margin-right: 5px;
  }
  
  .pagination a.active {
    background-color: #960C0C;
    padding: 10px 20px;
    color: white;
  }

  .sideBut{
    margin-bottom: 45px !important
  }

  th{
    margin:0.9rem 1.2rem 0.9rem 0 !important;
  }
  .bio-title{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      color: #2B1C1C;
  }
  .bio-data{

      font-size: 13px;
      line-height: 18px
  }
  .more-box{
      /*margin-right: -20px !important;*/
      padding-bottom: 25px !important;
  }
  .question-info{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;

      color: #00A998;
  }
  .question-label{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;

      color: #000000;
  }
  .score-circle{
      border-radius: 50%;
      background: #960C0C;
  }
.date, .time {
    width: 200px;
    margin-bottom: 10px;
    background: #FFFFFF;
    margin-left: 15px !important;
    padding-bottom: 5px !important;
    border: none;
    border-radius: 5px;
}

.closeBox {
    cursor: pointer;
}

.MuiDialogTitle-root {
    padding-bottom: 0px !important;
}


.dialog-heading {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    padding-bottom: 0px !important;
    /* identical to box height, or 112% */
    letter-spacing: -0.02em;

}

.dialog-des {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;

    color: #2B1C1C;
}
.ussd-des{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}


.dialog-main {
    /*margin-left: 76px !important;*/
    padding: 45px;
    border-radius: 16px !important;
}


.dialog-width {

    border-radius: 16px !important;
    /* .MuiDialog-paperWidthSm { */
    max-width: 450px;
    background-color: white !important;
    height: 200% !important;
    /* } */
}
.dialog-width-no-scroll{
    overflow-x: hidden;
    overflow-y: hidden;
}

.schedule-width {
    border-radius: 16px !important;
    /* .MuiDialog-paperWidthSm { */
    width: 500px !important;
    /*max-height: 60% !important;*/
    background-color: white !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #2B1C1C !important;
    padding-top: 8px !important;


}


::-webkit-input-placeholder {
    padding-top: 8px !important;
    color: #2B1C1C !important;
    padding-left: 5px !important;

}


::placeholder {
    padding-top: 8px !important;
    color: #2B1C1C !important;
    padding-left: 5px !important;

}

.invite-textarea {

    width: 270px !important;
    margin-left: 15px !important;

    height: 100px !important;
    /*margin-top: 10px !important;*/
    border: 1px solid rgba(150, 12, 12, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #ffffff;
    resize: none;
    overflow: auto;
    margin-bottom: 50px !important;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modalbtn {
    background: #960C0C !important;
    border-radius: 6px !important;
    margin-top: 25px;
    /*padding-right: 30px;*/
    /*padding-left: 30px;*/
    width: 390px;
}


.adminid {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;


}

.decisionRec {
    box-shadow: 0px 8px 12px rgba(108, 132, 120, 0.1);

}
/*.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{*/
/*  height: 100px !important;*/
/*}*/

.decision-radio {

    margin-left: 80px !important;

}

.date-and-time-input-box {
    display: flex;
    border: 1px solid rgba(150, 12, 12, 0.5);
    max-width: 285px;
    margin-left: 2px;
    border-radius: 6px;
    align-items: center;
    margin-bottom: 15px;
}
.ussd-radio{
    margin-left: 110px !important;
}
.transfer-radio{
    margin-left: 57px !important;
}

.schedule-box {
    /*display: flex;*/
    border: 1.5px solid rgba(150, 12, 12, 0.5);
    width: 360px !important;
    /*margin-left: 2px;*/
    border-radius: 6px;
    align-items: center;
    margin-bottom: 20px;
    height: 250px;
    padding: 0px !important;

}

.schedule-mode {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    /* identical to box height, or 117% */
    display: flex;
    align-items: flex-end;

    color: #960C0C;


}

.schedule-box img {
    width: 30px;
    height: 30px;
    background: rgba(255, 126, 29, 0.15);
    border-radius: 8px;
    /*flex: 1;*/
}

.date-and-time-input-box .react-datepicker-wrapper {
    flex: 4 1;
}

.date-and-time-input-box img {
    width: 20px;
    height: 20px;
    flex: 8 1;
}


.send-radio {
    margin-left: 58px !important;
}


.textareaComment {
    width: 290px !important;
    margin-left: 15px !important;

    height: 80px !important;
    margin-top: 10px !important;
    border: 1px solid rgba(150, 12, 12, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #ffffff;
    resize: none;
    overflow: auto;
    margin-bottom: 0px !important;
    padding-left: 30px;
    padding-top: 10px;
}

.decidetext {
    margin-top: 12px !important;
    margin-left: 10px;

}
.transLabel{
    margin-left: 0 !important;
}
.upload{
    height: 50px !important;
}
.uploadLabel{
    margin-left: 22px !important;
    margin-top: 10px !important;
    width: 100%;
    display: flex;
}
.upload img{
    margin-left: 85px !important;
}
.MuiInputBase-input::-webkit-input-placeholder{
    color: black !important;
    font-weight: bolder;
}
.amount{
    border: 1px solid rgba(150, 12, 12, 0.5);
    border-radius: 6px;
    width: 285px !important;
    margin-left: 15px !important;

    margin-bottom: 15px !important;

}
.MuiOutlinedInput-input {
    color: black !important;
}
.MuiInputLabel-formControl{
    margin-left: 13px !important;
    color: black !important;
}
.MuiOutlinedInput-root{
    height: 100% !important;
}
.paymentText {
    margin-top: 18px !important;
    margin-left: 10px;
}


.termsheading {

    margin-left: 32px !important;

}

.termscontent {

    margin-left: 52px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    font-family: IBM Plex Sans;
}

/*  */
.closeterms {

    padding: 13px 23px;
    margin-left: 30px;
    background: #FF2E2E;
    color: #FFFFFF;
    border: 1px solid rgba(43, 28, 28, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    /* margin-bottom: 20px !important; */

}

.cancelterms {

    padding: 7px 15px;
    color: #FF2E2E;
    background: #FFFFFF;
    border: 2px solid #FF2E2E;
    box-sizing: border-box;
    border-radius: 6px;
    /* margin-bottom: 20px !important; */

}

.schedule-status {

    padding: 7px 15px;
    color: #431288;;
    background: #D9D0E7;
    border: none !important;
    box-sizing: border-box;
    border-radius: 6px;
    margin-left: 80px;
    /* margin-bottom: 20px !important; */

}

.cancelsymbol {
    margin-top: 5px;
    font-size: 35px !important;
    float: left !important;
    font-weight: 100 !important;
    margin-left: 15px;
}

.cancelsym {
    margin-top: 5px;
    font-size: 35px !important;
    float: left !important;
    font-weight: 100 !important;
    margin-left: 0px !important;
}

.cancelsym:focus {
    color: #FF2E2E;
    cursor: pointer;
}

.cancelsymbol:hover {
    color: #FF2E2E;
    cursor: pointer;
}

.closetext {
    margin-left: 10px;
    margin-top: 15px;
}

.cancel {
    height: 40px;
    width: 40px;
}

.MuiTypography-body1 {

    color: #2B1C1C !important;
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 150% !important;
}

.MuiDialog-container {
    height: 90% !important;
    outline: 0;
    border-radius: 20px !important;
}

.scrollbar {
    overflow-y: scroll;
    margin-right: 50px
}

.scrollbar-primary {
    scrollbar-color: rgba(43, 28, 28, 0.15);
}

.scrollbar-primary::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(43, 28, 28, 0.15);;
}


/*
    Info Session
*/

.info {
    color: #147DB9;
    font-weight: 600;
}

.info:hover {
    color: #147DB9;
}

.info-title {
    margin-left: 60px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -0.02em;
    color: #2B1C1C;
}

.info-context {
    margin-left: 60px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #2B1C1C;
}

.home-icon {
    width: 23px;
    height: 23px;
    margin-top: 10px;
}

.check-icon {
    width: 23px;
    height: 23px;
    margin-left: 50px;
    margin-bottom: 20px;
    padding-left: 4px;
}

.info-phy {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-top: 50px;
    /* display: flex;
    align-items: flex-end; */
    color: #960C0C;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}


.MuiDialogContent-root {
    padding: 6px 18px !important;

}
.inviteLabel{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.02em;

    color: #2B1C1C;
}
.inviteWord{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-left: 7px;
    color: #2B1C1C;
}
.inviteWordRed{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-left: 7px;
    color: #960C0C;

}
.share{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-left: 7px;
    color: #2B1C1C;
}
.social{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin-left: 7px;
    color: #960C0C !important;
    /*margin-right: 5px;*/
}
 .social-link{
    cursor: pointer;
    color: #960C0C !important;
}
@media (max-width: 859px) {

    .dialog-main{
        padding: 0;
        margin-right: 15px;
    }
    .date-and-time-input-box img{
        margin-right: 5px;
    }
}


.files input {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    padding: 120px 0px 85px 35%;
    text-align: center !important;
    margin: 0;
    width: 100% !important;
}

.files input:focus {
    outline: 2px dashed #92b0b3;
    outline-offset: -10px;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    border: 1px solid #92b0b3;
}

.files {
    position: relative
}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.color input {
    background-color: #f1f1f1;
}

.files:before {
    position: absolute;
    bottom: 10px;
    left: 0;
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " or drag it here. ";
    display: block;
    margin: 0 auto;
    color: #2ea591;
    font-weight: 600;
    text-transform: capitalize;
    text-align: center;
}
:root {
    --primary-color: #FF2E2E;
}


body {
    font-family: 'IBM Plex Sans', sans-serif;
    /*overflow: hidden;*/
}


.create-new-pswd, .register {
    width: 90% !important;
}

.welmsg {
    font-weight: 500 !important;
    margin-bottom: 80px !important;
}

.welreset {
    font-weight: 500 !important;
    margin-bottom: 20px !important;
}

h3 {
    font-weight: 500;

}

input, button, select {
    border-radius: 4px;
    outline: none;

}

input::-webkit-input-placeholder {
    font-weight: lighter;
    color: #000;
}

input:-ms-input-placeholder {
    font-weight: lighter;
    color: #000;
}

input::placeholder {
    font-weight: lighter;
    color: #000;
}

input, select {
    background: white !important;
    padding: 16px;
    color: #2B1C1C;
    font-weight: 500;
    outline: none;
}


.agreelabel {
    display: flex;
    margin-bottom: 0px !important;
    margin-top: 12px;
    /*margin-left: 5px;*/
}

.tick {
    padding-left: 0px !important;
    margin-top: 20px;
}

element.style {
    width: 320px !important;
    height: 59px !important;
}

.bt, .bt-apply, .bt-createpass {
    background: #FF2E2E;
    color: #fff;
    border: 1px solid #FF2E2E;
    font-weight: bold;
    letter-spacing: 1.5px;
    transition: all .5s ease-in-out;
}

.bt:hover {
    background: #960C0C;
    border-color: #960C0C;
    /*opacity: .8;*/
}

.bt:active, button:focus {
    outline: none !important;
}
.payment{
    width: 105% !important;
}


.bt:disabled {
    background: #960C0C;
    border-color: #960C0C;
    /*opacity: .8;*/
}


.partner-btn button {
    background: transparent !important;
    color: #FF2E2E;
    font-weight: bold;
    border: 2px solid #FF2E2E;
    transition: all .5s ease-in-out;
}

.partner-btn button:hover {
    color: #FFF !important;
    background: #FF2E2E !important;
    background: var(--primary-color) !important;
    border-color: #FF2E2E;
    border-color: var(--primary-color);
}

img {
    vertical-align: middle;
    height: auto;
    width: 100%;
    max-width: 720px;
    max-height: 320px;

}

@media (max-width: 768px) {
    .create-new-pswd, .register {
        width: 100% !important;
        overflow: hidden !important;
    }
}

#mailErr {
    font-size: 12px;
}

@media only screen and (max-width: 600px) {

    span {
        position: relative !important;

    }


}

.MuiFormLabel-root.Mui-focused {
    color: #FF2E2E !important;
    font-family: IBM Plex Sans, serif !important;
    font-style: normal !important;
    font-style: normal !important;
}

.MuiOutlinedInput-input {
    color: black !important;
}

.terms {
    cursor: pointer;
    color: red;
    font-weight: bold;
    margin-left: 5px;
    font-size: 13px;
}

.terms:hover {
    text-decoration: none;
    color: #960C0C;
}

.agree {
    /*letter-spacing: 0.02em  !important;*/
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    transition: all .3s ease-in-out;
    /*margin-left: 30px;*/
}

.forget {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 55px;
    letter-spacing: 0.02em !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    font-family: IBM Plex Sans;

}

.bigbt {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 9px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    margin-top: 30px;

    color: #FFFFFF;
}

label {
    margin-right: 10px !important;
    margin-bottom: .0rem !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
}

.MuiTypography-body1 {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 15px !important;
}

.newField .MuiOutlinedInput-input {
    padding: 25px 0 20px 14px !important;
    /*border: 1px solid rgba(150, 12, 12, 0.5);*/

}

.genders {
    margin-right: 30px !important;
}

.MuiSvgIcon-root {

    height: 0.8em !important;
}

.MuiOutlinedInput-input:focus {
    border: none;
}

.MuiOutlinedInput-input:hover:focus {
    border: none !important;
}

.MuiOutlinedInput-input:hover:active {
    border: none !important;
}

.MuiFormGroup-root {
    display: block !important;
}

.MuiFormLabel-root {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    top: -5px !important;

    color: #5f5e5e !important;
}

.MuiFormControl-root {
    display: block !important;
}

.newField .MuiSelect-select.MuiSelect-select {
    padding-right: 24px !important;
    margin: 0 0 0 0 !important;
    padding-bottom: 23px !important;
}

.MuiSelect-selectMenu {
    background: white !important;
}

.form-title {
    margin-bottom: 40px;
    font-weight: bold;
}

/*New CSS styling*/
.form-container {
    min-height: 100% !important;
    background-color: #FFFFFF !important;
    /*height: 100% !important;*/
}
/*.react-tel-input .form-control{*/
/*    height: 80% !important;*/
/*}*/
.left-content {
    width: 385px;
}

.form {
    margin-top: 20px;
}

.MuiInputBase-input {
    height: 1px !important;
}

.site-logo, .partner-btn {
    padding-top: 27px;
}

.site-logo {
    width: 380px;
}

.left-form-div {
    flex: 4 1;
}

.site-logo, .partner-btn {
    height: 10.11%;
}

.form-img, .form-holder {
    height: 88.89%;
}

.form-img img {
    margin-bottom: 100px;
}

.right-ill-div {
    background: #FFF2C7;
    flex: 6 1;
}


.MuiCheckbox-colorSecondary.Mui-checked {
    color: #960C0C !important;
}

.MuiCheckbox-root {
    color: #960C0C !important;;
}

@media (max-width: 1100px) {
    .left-form-div {
        flex: 5 1;
    }

    .right-ill-div {
        flex: 5 1;
    }

    .login-right {
        display: none !important;
    }
}

@media (max-width: 860px) {
    .left-form-div {
        flex: 6 1;
    }

    .right-ill-div {
        flex: 4 1;
    }
}

@media only screen and (max-width: 600px) {
    .conPhone .newPhone {
        width: 170px !important;
    }

}

@media (max-width: 859px) {
    .right-ill-div {
        display: none;
    }

    .react-tel-input .form-control {
        width: 0 !important;
        /*margin-bottom: 25px ;*/
    }

    .left-form-div {
        width: 100%;
        height: 100%;
    }

    .err-image svg {
        width: 100%;
        margin-top: -120px;

    }
}

@media (max-width: 420px) {
    .left-content {
        width: 290px;
    }


}

/*.react-tel-input .form-control {*/
/*    font-size: 9px;*/
/*    background: #FFFFFF;*/
/*    border: 1px solid #CACACA;*/
/*    border-radius: 5px;*/
/*    margin-top: 18px !important;*/
/*    outline: none;*/
/*    transition: box-shadow ease .25s, border-color ease .25s;*/
/*}*/
.phone {
    pointer-events: none !important;
    width: 110px !important;
    height: 60px !important;
    border: 1px solid rgba(150, 12, 12, 0.5) !important;
}


.arrow {
    border-top: 3px solid red;
}

.react-tel-input :focus {
    border-color: #FF2E2E !important;
    box-shadow: 0 0 0 1px #FF2E2E !important;

}

.MuiSelect-outlined.MuiSelect-outlined {

    padding-bottom: 17px !important;
}

.conPhone .newPhone {
    padding-left: 15px !important;
    height: 43px !important;
    border-top-left-radius: 1px !important;

    margin-left: 10px !important;


}

.newPhone {
    width: 262px !important;
}

.conPhone .selected-flag {
    display: none !important;
    margin-left: -40px !important;
}

.conDial .phone + div:before {
    visibility: hidden !important;
    /*padding-left: 30px !important;*/
}

.conPhone .newPhone + div:before {
    visibility: hidden !important;

}

/*.react-tel-input .selected-flag:focus+div:before{*/
/*    color: #FF2E2E !important;*/
/*    visibility: visible!important;*/
/*    content: 'Phone ' !important;*/
/*    left: 15px !important;*/
/*    font-size: 13px !important;*/

/*}*/


.conPhone .newPhone:focus + div:before {
    color: #FF2E2E !important;
    visibility: visible !important;
    content: 'Phone number' !important;
    left: 15px !important;
    font-size: 12px !important;

}


.newPhone:not(:-ms-input-placeholder) + div:before {
    color: #5f5e5e !important;
    visibility: visible !important;
    content: 'Phone number ' !important;
    left: 15px !important;
    font-size: 12px !important;
}


.newPhone:not(:placeholder-shown) + div:before {
    color: #5f5e5e !important;
    visibility: visible !important;
    content: 'Phone number ' !important;
    left: 15px !important;
    font-size: 12px !important;
}

.conPhone .newPhone::-webkit-input-placeholder {
    color: black !important;
}

.conPhone .newPhone:-ms-input-placeholder {
    color: black !important;
}

.conPhone .newPhone::placeholder {
    color: black !important;
}

.conDial .selected-flag {

    margin-top: 8px !important;
}

.react-tel-input .selected-flag .flag {
    margin-top: -20px !important;
}

option:checked,
option:hover {
    box-shadow: 0 0 10px 100px #FF2E2E inset;
    color: white;
}


.age .MuiSelect-selectMenu {

    min-height: 0em !important;

}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.err-image {
    justify-content: center;
    height: 80vh !important;
    align-items: center;
    flex-direction: column;
    display: flex;
}

@media screen and (max-width: 600px) {
    .partners {
        display: none;
    }

    .patDiv {
        display: none;
    }
}

@media screen and (width: 768px) {
    .bac {
        /* max-width: 25.333333%!important; */
    }

    .partners {

    }
}

.bac {
    background: #FFF0CE;
}

.circle {
    background: white;
    height: 10vh;
    width: 10vh;
    border-radius: 50%;
    margin-top: 50px;
    margin-left: 65px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.thumb {
    margin-left: 55px !important;
    /*margin-bottom: 12px!important;*/
    margin-top: 50px !important;
}

.box {
    /*padding: 50px;*/
    min-height: 72vh;
    overflow: hidden;
    top: 5px;
    padding-bottom: 15px;
}

.text-area {
    height: 30vh;

    /*background: white;*/
}

.submit-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    margin-left: 70px;
    margin-right: 70px;

    /*line-height: 60px;*/
    /* or 111% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    color: #960C0C;
}

.p-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    margin-left: 70px;
    margin-right: 80px;

    margin-top: 30px;
    font-size: 13px;
    line-height: 175%;
    /* or 37px */

    letter-spacing: -0.02em;

    color: #2B1C1C;
}

.next-button {
    background: #FF2E2E;
    border: none;
    color: white;
    margin: 5.83% 46.88% 1.5% 20.62% !important;
    height: 52px;
    width: 180px
}

.circle2 {
    background: #FFF2C7 !important;
}

.newback {
    background: #F6F6F6 !important;
}

.rowl {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    background: white !important;
}

.side-new {
    background: white !important;
}

.prev-button {
    background: white;
    border-radius: 6px;
    /*padding: 13px;*/
    color: #FF2E2E;
    border: 2px solid #FF2E2E;
    margin-top: 15px;
    margin-left: 70px;
    height: 52px;
    width: 180px
}

.submit {
    background: #FF2E2E !important;
    /*border-radius: 6px;*/
    /*padding: 13px;*/
    /*border: none;*/
    color: white !important;
    /*margin-top: 70px;*/
}

.mt-md-5 {
    padding-bottom: 50px;
}

.back-to-dashboard {
    transition: all .5s ease;
}

.back-to-dashboard:hover {
    background: #FF2E2E;
    color: white;
}
.navBar {
    height: 100px;
}

.logo {
    padding: 20px;
}

.full-info {
    border-radius: 12px;
    background-color: #FFF2C7;
}

.summary-status {

}

.summary-second-div {;
}

.profile-card {
    background: #FFF2C7 !important;
    border-radius: 16px !important;
}
.student-border{
    border-bottom: 1px solid rgba(130, 130, 130, 0.15) !important;
    padding-bottom: 50px;
}

.fullInfoInner {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 350px;
    height: 350px;
    /*margin-top: 20px;*/
}

.opacityBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    opacity: 0.5;
    background: #FFF2C7;
    z-index: 5;
    transition: all .2s ease-in-out;
}

.opacityBoxGone {
    visibility: hidden;
    opacity: 0;
}

.onScroll {
    opacity: 0.67;
}

.fullInfoInner::-webkit-scrollbar {
    background: transparent !important;
    width: 6px;
    height: 40px;
}

.fullInfoInner::-webkit-scrollbar-thumb {
    background: rgba(43, 28, 28, 0.5);
    max-height: 40px !important;
    height: 40px !important;
    border-radius: 12px;
}


.styl {
    border-right: 0 solid lightgray;
    width: 185px;
    padding: 0;
}

.appH {
    height: 40vh;
}

.searchIn {
    padding: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.lin {
    padding-left: 90px;
}

.searchIn input:active {
    border: none white;
}

input {
    display: block;
}
/*Chrome*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type='range'] {
        overflow: hidden;
        width: 80px;
        -webkit-appearance: none;
        background-color: #9a905d;
    }

    input[type='range']::-webkit-slider-runnable-track {
        height: 10px;
        -webkit-appearance: none;
        color: #13bba4;
        margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
        width: 10px;
        -webkit-appearance: none;
        height: 10px;
        cursor: ew-resize;
        background: #434343;
        box-shadow: -80px 0 0 80px #43e5f7;
    }

}

/** FF*/
input[type="range"]::-moz-range-progress {
    background-color: #43e5f7;
}

input[type="range"]::-moz-range-track {
    background-color: #9a905d;
}

/* IE*/
input[type="range"]::-ms-fill-lower {
    background-color: #43e5f7;
}

input[type="range"]::-ms-fill-upper {
    background-color: #9a905d;
}
.userDetails_fullInfo__2G891 {
    background: #FFF2C7;
    max-width: 85%;
    margin-top: 50px;
    padding-top: 50px;
    padding-left: 50px;
    /*padding-bottom: 10px;*/
    border-radius: 10px;
    margin-bottom: 90px;
    padding-bottom: 10px;
    /*box-shadow: inset 0em -.5em rgba(43, 28, 28, 0.14);*/
    max-height: 600px;
    position: relative;
}

.userDetails_goBack__1szek {
    width: 150px;
    cursor: pointer;
}

.userDetails_goBack__1szek > span > img {
    width: 25px !important;
    height: 25px !important;
}

.userDetails_goBackText__1SaRe {

}

.userDetails_opacityBox__XnNh0 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    opacity: 0.5;
    background: #FFF2C7;
    z-index: 5;
    transition: all .2s ease-in-out;
}

.userDetails_opacityBoxGone__WUntT {
    visibility: hidden;
    opacity: 0;
}

.userDetails_fullInfoInner__jEBj_ {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 350px;
    height: 350px;
    /*margin-top: 20px;*/
}

.userDetails_onScroll__vbYBW {
    opacity: 0.67;
}

.userDetails_fullInfoInner__jEBj_::-webkit-scrollbar {
    background: transparent !important;
    width: 6px;
    height: 40px;
}

.userDetails_fullInfoInner__jEBj_::-webkit-scrollbar-thumb {
    background: rgba(43, 28, 28, 0.5);
    max-height: 40px !important;
    height: 40px !important;
    border-radius: 12px;
}


.userDetails_infoHeader__246km h4 {
    color: #960c0c;
    font-weight: bold !important;
    margin-bottom: 15px;
}

.userDetails_details__MdAyT {

}

.userDetails_detailsItem__3if_n {
    margin-top: 15px;
}

.userDetails_detailsItem__3if_n h5 {
    margin-top: 0;
    font-size: 1rem;
}

.userDetails_detailsItem__3if_n p {
    font-size: 14px;
}

.userDetails_label__1Tkd_ {
    margin-bottom: 10px !important;
}

.userDetails_value__3tS_X {
    font-weight: bold;
}

.userDetails_detailsItem__3if_n:nth-child(even) {
    width: 40% !important;
    max-width: 40%;
}

.userDetails_detailsItem__3if_n:nth-child(odd) {
    /*margin-right: 10px;*/
}

@media (max-width: 767px) {
    .userDetails_fullInfo__2G891 {
        max-width: 100%;
        padding-left: 20px;
    }
}

html, body, #root {
    min-height: 100vh;
    width: 100%;
    height: 100% !important;
}

html, body {
    overflow-y: visible !important;
    /*overflow-x: hidden;*/
    padding-right: 0 !important;
}

.myDashContainer {
    height: 100%;
}

.progressBar img {
    margin-top: 150px !important;
    height: 400px !important;
    max-height: 500px !important;
}

.myDashContainer img {
    margin-top: 0;
}

.navbar-brand img {
    width: 178px;
    height: 100%;
}

.icon img {
    width: 25px;
    height: 25px;
}

.hamburger img {
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.user-actions img {
    width: 20px;
    height: 30px;
}

/*.dashboard-navbar {*/
/*    background: #fff;*/
/*    !*height: 12vh !important;*!*/
/*    border-bottom: rgba(130, 130, 130, .15) solid 1px;*/
/*}*/

.my-navbar {
    background: #fff;
    border-bottom: rgba(130, 130, 130, .15) solid 1px;
    width: 100% !important;
    height: 13vh;
}

.header-dropdown img {
    width: 17px !important;
    margin-left: 5px;
}

.user {
    width: 175px;
}

.user-header {
    color: #960c0c;
}

.dropdown-icon {
    width: 20px;
    height: 20px;
}

.user-corner {
    width: 125px;
    cursor: pointer;
}

.user-image .image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #ccc;
}

.form-container {
    overflow-x: hidden;
}

.MuiMenuItem-root {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    /*background: red !important;*/
}

.MuiMenuItem-root:nth-child(2) {
    border-top: 2px solid rgba(229, 229, 229, 0.5);
    /*border-bottom: 2px solid rgba(229, 229, 229, 0.5);*/
}

.MuiListItemIcon-root {
    min-width: 36px !important;
    display: flex;
    align-items: center;
}

notification.MuiMenuItem-root .MuiTypography-root {
    word-wrap: break-word;
}

div a {
    color: black;
    text-decoration: none !important;
}

div a:hover {
    color: black;
}

.MuiListItemText-root {
    margin: 0 !important;
}

.MuiListItem-gutters {
    padding-right: 65px !important;
}

.image img {
    width: 40px;
    height: 40px;
}

.user-left {
    width: 72%;
}

.user-right {
    width: 28%;
    background: #FFF;
    margin-bottom: 2px;
    border-left: 1px solid rgba(130, 130, 130, 0.15);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    /*padding-left: 70px;*/
}

.horizontal-divider {
    height: 12%;
    width: 100%;
    border-bottom: 1px solid rgba(130, 130, 130, 0.15);
}

.notif-icon {
    cursor: pointer;
}

.menu {
    font-weight: bold;
}

.dashboard-holder {
    padding-top: 140px;
    padding-bottom: 50px;
    /*height: 100%;*/
}

.thumb-active {
    background-color: #00D689 !important;
}

/*.MuiSlider-markActive{*/
/*    background-color: #00D689 !important;*/
/*}*/


.dashboard-container {
    background: #FFFFFF;
    box-shadow: 0 15px 30px rgba(130, 130, 130, 0.15);
    border-radius: 10px;
    width: 90%;
    overflow: hidden;
}

.progressBar {
    flex: 1 1;
    -webkit-transform: translateX(25px);
            transform: translateX(25px);
    padding-top: 166px;
    /*transform: rotate(180deg);*/
}

.dashboard-inner-container {
    width: 100%;
}

.greeting h3 {
    /*font-family: "IBM Plex Sans";*/
    color: #B1B1B1;
    font-weight: bold;
}

.dashboard-content {
    padding: 60px 70px 100px 40px;
    flex: 20 1;
}

.questionnaire-row,
.test-row,
.interview-row,
.admission-row {
    padding: 30px 20px 15px;
    border-radius: 6px;
    background: #FFF2C7;
    margin-bottom: 40px;
}

.questionnaire-icon, .test-icon, .interview-icon {
    width: 15%;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
}

.questionnaire-icon .icon {
    background: #00D689;
}

.test-icon .icon {
    background: #1C8AEC;
}

.interview-icon .icon {
    background: #A07DD0;
}

.payment-icon .icon {
    background: #FF7E1D;
}

.enrollment-icon .icon {
    background: #FFB403;
}

.text-and-btn {
    width: 100%;
    margin-left: 10px;
}

.text {
    width: 50%;
}

.text-and-btn h6 {
    font-weight: bold;
    color: #2B1C1C;
}

.dashboard-btn {
    padding-right: 20px;

}

.text-and-btn button {
    font-size: 14px !important;
    height: 33px !important;
    min-width: 110px !important;
    font-weight: bold;
    border-radius: 8px;
}

.btn-arrow img {
    width: 20px !important;
    height: 15px !important;
    margin-left: 10px;
}

/*.test-row button, .interview-row button {*/
/*    background: #2B1C1C !important;*/
/*    border-color: #2B1C1C !important;*/
/*}*/

.apx {
    font-weight: lighter;
    font-size: 8px;
}

.steps-and-time {
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    padding-bottom: 3px;
    width: 160px;
}

.steps-and-time p {
    margin: 0 !important;
}

.side-bar {
    height: 88%;
}

.close-btn {
    display: none;
}

.applicant-details {
    height: 60%;
    /*margin-top: -50px;*/
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(130, 130, 130, 0.15);
}

.applicant-id {
    color: rgba(43, 28, 28, 0.5);
    font-size: 14px;
}

.applicant-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ccc;
    /* background: #FFF2C7; */
}


.pen {
    padding-left: 60px;
}

.applicant-name h4 {
    color: rgba(43, 28, 28, 0.85);
    font-size: 1.3rem;
    font-weight: bold;
}

.applicant-status {
    background: #FFF0CE;
    border: 1px solid #FFB403;
    color: #960c0c;
    border-radius: 2px;
    font-size: 14px;
}

.applicant-status h5 {
    font-size: inherit;
    font-weight: bold;
}

.complaint-icon, .log-out-icon, .comment-icon {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 15px;
}

.comment-icon {
    background: #c8e7f8;
}

.comment-icon + span {
    color: #147DB9;
    font-weight: 600;
}

.complaint-icon {
    background: #FFDDD9;
}

.complaint-icon + span {
    color: #FF7E1D;
    font-weight: 600;
}

.log-out-icon {
    background: rgba(67, 18, 136, 0.2);
}

.log-out-icon + span {
    color: #431288;
    font-weight: 600;
}

.user-image {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.questionnaire-btn {
    width: 140px;
    height: 76%;
    border-radius: 6px;
}

.audio {
    border: 1px solid #FF7E1D;
    padding: 15px 10px;
    border-radius: 8px;
}

.audio img {
    width: 25px !important;
    height: 25px !important;
}


@media only screen and (max-width: 991px) {
    .user-corner {
        display: none !important;
    }

    .hamburger-button {
        display: block !important;
    }

    .user {
        width: auto;
        width: initial;
        margin-right: -30px;
    }

    .myDashContainer {
        position: relative;
        overflow-x: hidden;
    }


    .horizontal-divider {
        display: none;
    }

    /*.dashboard-holder {*/
    /*    position: relative;*/
    /*    overflow-x: hidden;*/
    /*}*/
    .dashboard-holder::-webkit-scrollbar {
        display: none;
    }

    .questionnaire-row,
    .test-row,
    .interview-row,
    .admission-row {
        flex-direction: column;
    }

    .dashboard-btn {
        padding-right: 0;
    }

    .user-left {
        width: 100%;
    }

    .text-and-btn {
        margin-top: 18px;
        margin-left: 0;
    }

    .steps-and-time {
        flex-direction: column !important;
    }

    .steps + span + p {
        margin-top: 5px !important;
    }

    .steps + span {
        display: none;
    }

    .text-and-btn h6 {
        font-size: 26px !important;
        max-width: 150px;
        width: 150px;
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .text.text-nowrap {
        white-space: normal !important;
        white-space: initial !important;
    }

    .questionnaire-btn {
        height: 45px !important;
        min-width: 0px;
    }

    .user-right {
        position: fixed;
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        top: 0;
        right: 0;
        width: 80% !important;
        min-height: 100vh !important;
        z-index: 10000;
        transition: all 0.8s ease-in-out;
    }

    .user-actions {
        justify-content: center !important;
    }

    .user-right-in-view {
        -webkit-transform: translateX(0);
                transform: translateX(0);
    }

    .side-bar-pause {
        overflow-y: hidden;
    }

    .close-btn {
        display: block;
    }

    .progressBar img {
        height: 500px !important;
        max-height: 500px;
        width: 24px !important;
        max-width: 24px !important;
    }
}

@media only screen and (max-width: 767px) {
    .desktop-progress-bar {
        display: none;
    }

    .progressBar {
        display: none;
    }

    .dashboard-container {
        padding: 30px 10px;
    }

    .dashboard-content {
        padding: 0 !important;
    }
}


@media only screen and (max-width: 600px) {

    .dashboard-container {
        width: 100% !important;
    }

    .greeting h3 {
        margin: auto;
        text-align: center;
        font-size: medium;
        font-size: initial;
    }

    .questionnaire-row,
    .test-row,
    .interview-row,
    .admission-row {
        flex-direction: column;
        margin-left: 15px;
        margin-bottom: 20px;
        display: block;
    }

    .text-and-btn h6 {
        font-size: smaller;
        margin-left: 20px;
    }

    .steps-and-time {
        margin-left: 20px;
        font-size: small;

    }

    .icon {
        margin-left: 20px;
    }

    .dashboard-btn {

        margin-left: 20px;
    }

    .pend {
        padding-bottom: 5px;
        padding-top: 5px;
    }


}

@media (min-width: 991px) {
    .dashboard-content-scrollable {
        max-height: 650px;
        overflow: scroll;
    }

    .dashboard-content-scrollable::-webkit-scrollbar {
        background: transparent !important;
        width: 9px;
        height: 20px;
    }

    .dashboard-content-scrollable::-webkit-scrollbar-thumb {
        background: rgba(43, 28, 28, 0.5);
        max-height: 20px !important;
        height: 20px !important;
        border-radius: 12px;
    }
}
@media screen and (min-width: 600px) {
    .vh-79 {
        padding: 30px;
        margin-top: 100px;
        /*width: 100%;*/
        /*min-height: 75vh;*/
        /*position: absolute;*/
        /*bottom: 0;*/
    }

    .firstRad {
        position: relative;
        top: 2px !important;
    }

    .secondRad {
        position: relative;
        top: -100px !important;
    }

}

@media (max-width: 530px) {
    .agreementInput {
        width: 100% !important;
    }
}

@media screen and (max-width: 200px) {
    .vh-79 {
        padding: 30px;
        /*position: absolute;*/
        /*top:250px;*/
        margin-top: 100px;
        bottom: 0;
        background: white;
    }

    .firstRad {
        position: relative;
        top: 21px !important;
    }

    .secondRad {
        position: relative;
        top: -47px !important;
    }

}
.MuiOutlinedInput-root fieldset{
    border: 1px solid rgba(150, 12, 12, 0.5) !important;
}
.formContainers {
    overflow: hidden;
}
.multipleCheck{
    width: 10px !important;
    /*margin-right: -50px;*/
}
.MuiPopover-paper{
    max-height: 220px !important;
    padding-top: 8px !important;
    width: 240px !important;
    /*min-height: 195px !important;*/
}

.menu{
    max-height: 148px !important;
    min-height: 148px !important;
    padding-top: 8px !important;
    width: 250px !important;
}
/*.MuiOutlinedInput-root{*/
/*    height: 50px !important;*/
/*}*/
.MuiOutlinedInput-root .industryHeight{
    height: 100% !important;
}
.personalCon{
    margin: 120px auto;
}
.row-spacing{
    margin-top: 15px;
}
.health-radio{
    margin-left: 44px ;
}


.textField {
    padding-bottom: 40px !important;
}

.heat {
    height: 70px;
    background: red;
    width: 100%;
}

/*.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {*/
/*    padding-left: 6px !important;*/
/*}*/
/*.sets {*/
/*    padding: 9.5px 4px !important;*/
/*}*/
.MuiInputBase-input {
    margin: 0 !important;
}

.MuiFormControl-root {
    width: 100% !important;
}

.MuiInputBase-input {
    margin-bottom: 15px !important;
}

.MuiInputBase-input {
    margin: 0 !important;
}

.MuiAutocomplete-tag {
    /*margin: 3px;*/
    max-width: calc(100% - 6px);
    margin-bottom: 9px !important;
}

/*.MuiChip-label {*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*    padding-left: 12px;*/
/*    padding-right: 12px;*/
/*    margin-bottom: 23px;*/
/*    text-overflow: ellipsis;*/
/*}*/

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 20px 0 20px 0 !important;
    font-size: 19px;
}

.MuiAutocomplete-inputRoot .MuiAutocomplete-input {
    width: 0 !important;
    min-width: 20px !important;
}

.MuiAutocomplete-inputFocused {
    opacity: 1;
    margin-top: 40px;
}

.MuiInputBase-fullWidth {
    width: 100%;
    min-height: 60px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 9.5px 4px;
    min-height: 20px !important;
    margin-bottom: 0 !important;
}

.newText .MuiOutlinedInput-input {
    padding: 18.5px 14px;
    margin-bottom: 6px !important;
    margin-top: 6px !important;
}

.shad {
    /*box-shadow: 0px 15px 30px rgba(130, 130, 130, 0.15);*/
    margin-top: 30px;
}

.MuiSelect-selectMenu {
    height: auto;
    padding-bottom: 23.5px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
}

.radioButton .MuiSvgIcon-root {
    fill: currentColor;
    width: 1em;
    height: 0.7em;
    /* display: inline-block; */
    font-size: 1.5rem;
    /* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
    flex-shrink: 0;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.radioText .MuiTypography-body1 {
    font-size: 0.8rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.PrivateSwitchBase-root-135 {
    padding: 9px 0 9px 0 !important;
}

.small, small {
    font-family: IBM Plex Sans, serif;
    font-size: 90%;
    font-weight: 400;
    margin-top: 6px;
    /*font-style: normal;*/
    /*font-weight: 400;*/
    /*font-size: 15px;*/
    /*line-height: 23px;*/
    /*display: flex;*/
    /*align-items: center;*/
    position: relative;
    top: 10px;

    color: #4e4d4d;
    width: 80%;
}

.alignRadio {
    float: right
}

.firstRad {
    position: relative;
    top: 2px !important;
}

.secondRad {
    position: relative;
    top: -40px !important;
}

.MuiFormGroup-root {
    width: 100% !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child {
    padding-left: 6px !important;
    font-size: 15px;
    margin-top: -4px !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-right: 32px;
    padding-bottom: 30px !important;
}

.shadow-3 {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, .2);
    background: white;
}

.MuiChip-root {
    color: black !important;
    background-color: #FFF2C7 !important;
}

.MuiChip-deleteIcon {
    color: red !important;
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
    -webkit-appearance: listbox;
    margin-bottom: 6px !important;
    margin-top: 5px !important;
}

.MuiOutlinedInput-root {
    width: 100% !important;
}

.back {
    margin-left: 0 !important;
}

.centers {
    text-align: center !important;
    position: relative;
    left: 30px;
}

/*.sets{*/
/*    margin-bottom: 30px !important;*/
/*    border: 2px solid red;*/
/*}*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="date"] {
    position: relative;
    /*padding: 10px;*/
}

input[type="date"]::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
}

input[type="date"]:before {
    content: attr(label);
    position: absolute;
    color: #960C0C;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    color: rgba(0, 0, 0, 0.65);
    pointer-events: none;
    line-height: 1.5;
    padding: 0 0.5rem;
}

input[type="date"]:focus:before,
input[type="date"]:not([value=""]):before {

    display: none;

}

.MuiAutocomplete-option[data-focus="true"] {
    background-color: rgba(192, 3, 3, 0.04) !important;
}

.PrivateSwitchBase-root-136 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-262 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-292 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-322 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-352 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-425 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-455 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-485 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-335 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-408 {
    padding: 0 0 0 9px !important;
}

.PrivateSwitchBase-root-438 {
    padding: 0 0 0 9px !important;
}

.MuiRadio-root {
    color: rgba(148, 23, 23, 0.54);
    padding: 0 0 0 9px !important;
}

.vh-79 {
    padding: 30px;
    margin-top: 100px;
    /*width: 100%;*/
    /*min-height: 75vh;*/
    /*position: absolute;*/
    /*bottom: 0;*/
}

.MuiAutocomplete-option[aria-selected="true"] {
    background-color: rgba(246, 9, 9, 0.08) !important;
}

/*.MuiOutlinedInput-input {*/
/*    color: rgba(0, 0, 0, 0)!important;*/
/*    !* display: none !important; *!*/
/*}*/
.calender {
    content: url(/static/media/calendar1.4dfeca62.svg) !important;
    position: absolute !important;
    top: 33px;
    right: 30px;
    /*margin-left: 80%;*/
    width: 17px !important;
    height: 20px;
    z-index: 1;


}

.MuiSelect-outlined.MuiSelect-outlined {
    padding-bottom: 20px !important;
}

input[type="date"] {
    position: relative;
    padding-bottom: 27px;

}
.agreementText{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;

    color: #2B1C1C;
}
.agreementDiv{
    margin-right: 25px;
    margin-left:25px
}
.planText{
    font-family: IBM Plex Sans;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    margin-left: 17px;
    letter-spacing: 0.05em;

    color: rgba(43, 28, 28, 0.8);
}
.paymentTerms{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    color: #2B1C1C;

}
.paymentCheck{
    font-family: IBM Plex Sans;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 11px !important;
    line-height: 19px !important;
    display: flex !important;
    align-items: center !important;
    letter-spacing: 0.02em;

    color: #2B1C1C;


}
.cancelTerms {
    padding-left: 15px;
    padding-right: 15px;
    /*padding: 14px 25px;*/
    /*margin-left: 30px;*/
    color: #FF2E2E;
    background: #FFFFFF;
    border: 1px solid rgba(43, 28, 28, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    /* margin-bottom: 20px !important; */

}
.gender {
    margin-right: 30px !important;
}

.second-rad {
    position: relative;
    top: -10px !important;
}
.align-radio {
    float: right;

}
.stamp-div{
    width: 150px !important;
    height: 150px !important;
    margin: auto!important;
}
@media (max-width: 768px) {
    .gender{
        margin-right: 0;
        margin-bottom: 5px;
    }
    .health-radio{
        margin-left: 10px;
    }
    .align-radio{
        float: none;
        margin-top: 10px;
    }
}

/*.fixed-form-width {*/
/*    max-width: 600px;*/
/*}*/

@media screen and (max-width: 600px) {
    .cover-div {
        background: white !important;
        margin: 1px 0 0 20px;
        min-height: 60vh;
        min-width: 100vw;
        max-height: 200vh;
        padding: 25px 30px 0;
    }

    .image-div {
        min-width: 480px;
        background: white;
        border-radius: 12px;
    }

    .newOptionDiv {

    }
}

@media screen and (min-width: 800px) {
    .cover-div {
        padding: 30px;
        width: 850px;
        background: white;
        align-self: center;
        margin-top: 10% !important;
    }

    .image-div {
        height: 400px;
        width: 550px;
        background: white;
        border-radius: 12px;
    }

    .newOptionDiv {

    }
}

#nextButton {
    background: #FF7E1D;
    border-radius: 6px;
    width: 100px;
    height: 35px;
    color: white;
    border: none;
}

#nextButton:hover {
    background: #FF8f8f;
    color: white;
}

.question {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    /*line-height: 47px;*/
    /*display: flex;*/
    align-items: center;
    color: #960C0C;
    padding: 0;
}

.timer {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    /*line-height: 27px;*/
    /*display: flex;*/
    align-items: center;
    text-align: right;
    color: #FF2E2E;
}

.options {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    /*line-height: 27px;*/
    /*display: flex;*/
    align-items: center;
    color: #2B1C1C;
}

.pagination {
    float: left !important;
}

.image-div {
    min-height: 0;
    min-width: 200px;
    background: white;
    border-radius: 12px;
}

.mover {
    height: 5px;
    /*margin-top: 12px;*/
    /*width: 10px;*/
    /*border-bottom: 3px solid gray;*/
    background: #FFB403;
}

hr {
    margin-top: 0;
    position: relative;
    top: 19px;
    width: 100px
}

.spar {
    margin-left: 10px;
    position: relative;
    top: -5px
}

.mb5s {
    margin-bottom: 8rem !important;
}

.newOptionDiv {
    margin: 0 0 35px 15px;
}

.MuiFormGroup-root {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px !important;
    flex-direction: column;
}

