.styl {
    border-right: 0 solid lightgray;
    width: 185px;
    padding: 0;
}

.appH {
    height: 40vh;
}

.searchIn {
    padding: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
}

.lin {
    padding-left: 90px;
}

.searchIn input:active {
    border: none white;
}

input {
    display: block;
}