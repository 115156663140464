.fullInfo {
    background: #FFF2C7;
    max-width: 85%;
    margin-top: 50px;
    padding-top: 50px;
    padding-left: 50px;
    /*padding-bottom: 10px;*/
    border-radius: 10px;
    margin-bottom: 90px;
    padding-bottom: 10px;
    /*box-shadow: inset 0em -.5em rgba(43, 28, 28, 0.14);*/
    max-height: 600px;
    position: relative;
}

.goBack {
    width: 150px;
    cursor: pointer;
}

.goBack > span > img {
    width: 25px !important;
    height: 25px !important;
}

.goBackText {

}

.opacityBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    opacity: 0.5;
    background: #FFF2C7;
    z-index: 5;
    transition: all .2s ease-in-out;
}

.opacityBoxGone {
    visibility: hidden;
    opacity: 0;
}

.fullInfoInner {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 350px;
    height: 350px;
    /*margin-top: 20px;*/
}

.onScroll {
    opacity: 0.67;
}

.fullInfoInner::-webkit-scrollbar {
    background: transparent !important;
    width: 6px;
    height: 40px;
}

.fullInfoInner::-webkit-scrollbar-thumb {
    background: rgba(43, 28, 28, 0.5);
    max-height: 40px !important;
    height: 40px !important;
    border-radius: 12px;
}


.infoHeader h4 {
    color: #960c0c;
    font-weight: bold !important;
    margin-bottom: 15px;
}

.details {

}

.detailsItem {
    margin-top: 15px;
}

.detailsItem h5 {
    margin-top: 0;
    font-size: 1rem;
}

.detailsItem p {
    font-size: 14px;
}

.label {
    margin-bottom: 10px !important;
}

.value {
    font-weight: bold;
}

.detailsItem:nth-child(even) {
    width: 40% !important;
    max-width: 40%;
}

.detailsItem:nth-child(odd) {
    /*margin-right: 10px;*/
}

@media (max-width: 767px) {
    .fullInfo {
        max-width: 100%;
        padding-left: 20px;
    }
}
