@import url('https://fonts.googleapis.com/css?family=IBM+Plex+Sans&display=swap');

:root {
    --primary-color: #FF2E2E;
}


body {
    font-family: 'IBM Plex Sans', sans-serif;
    /*overflow: hidden;*/
}


.create-new-pswd, .register {
    width: 90% !important;
}

.welmsg {
    font-weight: 500 !important;
    margin-bottom: 80px !important;
}

.welreset {
    font-weight: 500 !important;
    margin-bottom: 20px !important;
}

h3 {
    font-weight: 500;

}

input, button, select {
    border-radius: 4px;
    outline: none;

}

input::placeholder {
    font-weight: lighter;
    color: #000;
}

input, select {
    background: white !important;
    padding: 16px;
    color: #2B1C1C;
    font-weight: 500;
    outline: none;
}


.agreelabel {
    display: flex;
    margin-bottom: 0px !important;
    margin-top: 12px;
    /*margin-left: 5px;*/
}

.tick {
    padding-left: 0px !important;
    margin-top: 20px;
}

element.style {
    width: 320px !important;
    height: 59px !important;
}

.bt, .bt-apply, .bt-createpass {
    background: #FF2E2E;
    color: #fff;
    border: 1px solid #FF2E2E;
    font-weight: bold;
    letter-spacing: 1.5px;
    transition: all .5s ease-in-out;
}

.bt:hover {
    background: #960C0C;
    border-color: #960C0C;
    /*opacity: .8;*/
}

.bt:active, button:focus {
    outline: none !important;
}
.payment{
    width: 105% !important;
}


.bt:disabled {
    background: #960C0C;
    border-color: #960C0C;
    /*opacity: .8;*/
}


.partner-btn button {
    background: transparent !important;
    color: #FF2E2E;
    font-weight: bold;
    border: 2px solid #FF2E2E;
    transition: all .5s ease-in-out;
}

.partner-btn button:hover {
    color: #FFF !important;
    background: var(--primary-color) !important;
    border-color: var(--primary-color);
}

img {
    vertical-align: middle;
    height: auto;
    width: 100%;
    max-width: 720px;
    max-height: 320px;

}

@media (max-width: 768px) {
    .create-new-pswd, .register {
        width: 100% !important;
        overflow: hidden !important;
    }
}

#mailErr {
    font-size: 12px;
}

@media only screen and (max-width: 600px) {

    span {
        position: relative !important;

    }


}

.MuiFormLabel-root.Mui-focused {
    color: #FF2E2E !important;
    font-family: IBM Plex Sans, serif !important;
    font-style: normal !important;
    font-style: normal !important;
}

.MuiOutlinedInput-input {
    color: black !important;
}

.terms {
    cursor: pointer;
    color: red;
    font-weight: bold;
    margin-left: 5px;
    font-size: 13px;
}

.terms:hover {
    text-decoration: none;
    color: #960C0C;
}

.agree {
    /*letter-spacing: 0.02em  !important;*/
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    transition: all .3s ease-in-out;
    /*margin-left: 30px;*/
}

.forget {
    text-align: center;
    margin-top: 25px;
    margin-bottom: 55px;
    letter-spacing: 0.02em !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    font-family: IBM Plex Sans;

}

.bigbt {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 9px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    margin-top: 30px;

    color: #FFFFFF;
}

label {
    margin-right: 10px !important;
    margin-bottom: .0rem !important;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 15px;
}

.MuiTypography-body1 {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    line-height: 15px !important;
}

.newField .MuiOutlinedInput-input {
    padding: 25px 0 20px 14px !important;
    /*border: 1px solid rgba(150, 12, 12, 0.5);*/

}

.genders {
    margin-right: 30px !important;
}

.MuiSvgIcon-root {

    height: 0.8em !important;
}

.MuiOutlinedInput-input:focus {
    border: none;
}

.MuiOutlinedInput-input:hover:focus {
    border: none !important;
}

.MuiOutlinedInput-input:hover:active {
    border: none !important;
}

.MuiFormGroup-root {
    display: block !important;
}

.MuiFormLabel-root {
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 15px !important;
    top: -5px !important;

    color: #5f5e5e !important;
}

.MuiFormControl-root {
    display: block !important;
}

.newField .MuiSelect-select.MuiSelect-select {
    padding-right: 24px !important;
    margin: 0 0 0 0 !important;
    padding-bottom: 23px !important;
}

.MuiSelect-selectMenu {
    background: white !important;
}

.form-title {
    margin-bottom: 40px;
    font-weight: bold;
}

/*New CSS styling*/
.form-container {
    min-height: 100% !important;
    background-color: #FFFFFF !important;
    /*height: 100% !important;*/
}
/*.react-tel-input .form-control{*/
/*    height: 80% !important;*/
/*}*/
.left-content {
    width: 385px;
}

.form {
    margin-top: 20px;
}

.MuiInputBase-input {
    height: 1px !important;
}

.site-logo, .partner-btn {
    padding-top: 27px;
}

.site-logo {
    width: 380px;
}

.left-form-div {
    flex: 4;
}

.site-logo, .partner-btn {
    height: 10.11%;
}

.form-img, .form-holder {
    height: 88.89%;
}

.form-img img {
    margin-bottom: 100px;
}

.right-ill-div {
    background: #FFF2C7;
    flex: 6;
}


.MuiCheckbox-colorSecondary.Mui-checked {
    color: #960C0C !important;
}

.MuiCheckbox-root {
    color: #960C0C !important;;
}

@media (max-width: 1100px) {
    .left-form-div {
        flex: 5;
    }

    .right-ill-div {
        flex: 5;
    }

    .login-right {
        display: none !important;
    }
}

@media (max-width: 860px) {
    .left-form-div {
        flex: 6;
    }

    .right-ill-div {
        flex: 4;
    }
}

@media only screen and (max-width: 600px) {
    .conPhone .newPhone {
        width: 170px !important;
    }

}

@media (max-width: 859px) {
    .right-ill-div {
        display: none;
    }

    .react-tel-input .form-control {
        width: 0 !important;
        /*margin-bottom: 25px ;*/
    }

    .left-form-div {
        width: 100%;
        height: 100%;
    }

    .err-image svg {
        width: 100%;
        margin-top: -120px;

    }
}

@media (max-width: 420px) {
    .left-content {
        width: 290px;
    }


}

/*.react-tel-input .form-control {*/
/*    font-size: 9px;*/
/*    background: #FFFFFF;*/
/*    border: 1px solid #CACACA;*/
/*    border-radius: 5px;*/
/*    margin-top: 18px !important;*/
/*    outline: none;*/
/*    transition: box-shadow ease .25s, border-color ease .25s;*/
/*}*/
.phone {
    pointer-events: none !important;
    width: 110px !important;
    height: 60px !important;
    border: 1px solid rgba(150, 12, 12, 0.5) !important;
}


.arrow {
    border-top: 3px solid red;
}

.react-tel-input :focus {
    border-color: #FF2E2E !important;
    box-shadow: 0 0 0 1px #FF2E2E !important;

}

.MuiSelect-outlined.MuiSelect-outlined {

    padding-bottom: 17px !important;
}

.conPhone .newPhone {
    padding-left: 15px !important;
    height: 43px !important;
    border-top-left-radius: 1px !important;

    margin-left: 10px !important;


}

.newPhone {
    width: 262px !important;
}

.conPhone .selected-flag {
    display: none !important;
    margin-left: -40px !important;
}

.conDial .phone + div:before {
    visibility: hidden !important;
    /*padding-left: 30px !important;*/
}

.conPhone .newPhone + div:before {
    visibility: hidden !important;

}

/*.react-tel-input .selected-flag:focus+div:before{*/
/*    color: #FF2E2E !important;*/
/*    visibility: visible!important;*/
/*    content: 'Phone ' !important;*/
/*    left: 15px !important;*/
/*    font-size: 13px !important;*/

/*}*/


.conPhone .newPhone:focus + div:before {
    color: #FF2E2E !important;
    visibility: visible !important;
    content: 'Phone number' !important;
    left: 15px !important;
    font-size: 12px !important;

}


.newPhone:not(:placeholder-shown) + div:before {
    color: #5f5e5e !important;
    visibility: visible !important;
    content: 'Phone number ' !important;
    left: 15px !important;
    font-size: 12px !important;
}

.conPhone .newPhone::placeholder {
    color: black !important;
}

.conDial .selected-flag {

    margin-top: 8px !important;
}

.react-tel-input .selected-flag .flag {
    margin-top: -20px !important;
}

option:checked,
option:hover {
    box-shadow: 0 0 10px 100px #FF2E2E inset;
    color: white;
}


.age .MuiSelect-selectMenu {

    min-height: 0em !important;

}


input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.err-image {
    justify-content: center;
    height: 80vh !important;
    align-items: center;
    flex-direction: column;
    display: flex;
}
