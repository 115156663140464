@media screen and (max-width:600px){
    .top-card{
        background: white
    }
    .side-menu{
        max-width: 70px !important;
    }
    .off{
        display: none !important;
    }
    .export{
        margin-right: 0em !important;
    }
    .has-search .form-control-feedback{
        height: 1em !important;
        text-align: left !important;
        position: absolute !important;
    }
    .tops{
        margin-top: 18px;
    }
    .testing{
        margin-top: 20px;
    }
    .svg-top{
        display: none !important;
    }
    .full{
        margin: 4px !important;
    }
    select{
        display:none
    }
    .pagination{
        margin: 0em;
    }
}

table{
    margin-top: -20px !important;
}

.full{
    /*position: absolute;*/
    width: auto;
    /*height: 500px;*/
    /* max-height: 800px; */
    margin: 0px 30px;
    background-color: white;
    border-radius: 18px;
}
.top-card{
    height: 50px;
    width: 100% !important;
    padding: -4px;
    border-bottom: 1px solid lightgray;
}
.side-menu{
    padding: 80px 0 0 0;
    border-right: 1px solid lightgray;
    color: rgba(43, 28, 28, 0.5);
    font-weight: bold;
    flex: 1;
    max-width: 240px;
}
.profile-menu{
    /*padding: 90px 0 0 0;*/
    /*border-right: 1px solid lightgray;*/
    color: rgba(43, 28, 28, 0.5);
    font-weight: bold;
    flex: 1;
    /*margin-left: -1190px !important;*/
    /*max-width: 240px;*/
}
.card-class{
    border-left: 1px solid lightgray;
}
.student-id{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    padding: 8px;
    font-size: 14px;
    /*line-height: 18px;*/
    /*display: flex;*/
    align-items: center;
    /*text-align: center;*/
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: rgba(43, 28, 28, 0.5);
}
/* .paginate{

} */
.pic-div{
    background-color: #fceba7;
    height:120px;
    width: 120px;
    border-radius: 50%;
    margin-bottom: 15px;
    /*border: 3px solid blue;*/
}
.status{
    background: #FFF0CE;
    border: 2px solid #FFB403;
    box-sizing: border-box;
    border-radius: 6px;
    /*padding: 12px;*/
    margin: auto;
    width: 180px ;
    height: 30px;
    color: #960C0C;
}

.invite-box{
    width: 25px;
    height: 25px;
    background: #FFDDD9;
    position: relative;
    cursor: pointer;
    border-radius: 6px;
    /*top: 10px;*/
    /*right: 10px;*/
    /*margin-top: -60px;*/
}
.comment-box{
    background: rgba(67, 18, 136, 0.2);
    width: 25px;
    height: 25px;
    position: relative;
    border-radius: 6px;
    cursor: pointer;
    /*top: 10px;*/
    /*right: 10px;*/

}
.decision-box{
    background: #C3F7E8;;
    width: 25px;
    height: 25px;
    position: relative;
   border-radius: 6px;
    cursor: pointer;
}

.invite{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    align-items: center;
    letter-spacing: 0.02em;
    color: #FF7E1D;
    margin-top: 80px;
    cursor: pointer;
}
.add-comments{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.02em;
    color: #431288;
    margin-top: 40px;
    cursor: pointer;
}
.make-decision{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.02em;
    color: #00A998;
    margin-top: 40px;
    cursor: pointer;

}
.route-div{
    padding: 15px;
    /*background-color: #00A998;*/
}

.summary-first-div{
    background-color: #fceba7;
    height: 180px;
    border-radius: 18px;
}
.summary-second-div{
    border-radius: 18px;
    height: 330px;
    /*background-color: #FF7E1D;*/
}
.summary-second-div1{
    border-radius: 18px;
    min-width: 300px;
    min-height: 330px;
    background-color: #fceba7;
    margin-left: -10px;
}
.range-form {
    background: blue;
    width:250px;
}
.app-status{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height */
    /*display: flex;*/
    /*align-items: center;*/
    letter-spacing: 0.05em;
}

div a{
    color: gray;
}

/*div a:active{*/
/*    color: #431288;*/
/*    border-right: 3px solid #431288;*/
/*}*/
.side-active:hover {
    border-right: 5px solid #431288;
}


.active-indicator {
    width: 5px;
    background: white;
}

.active-indicator-fill {
    background: #431288;
}

#searchInput{
    background-image: url('../images/searchIcon.png');
}

.tester{
    display: none;
}
.testing{
    display: block;
}

.div-active {
      border-right: 5px solid #431288;
  }
.summary-head{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    margin-bottom: 20px;

    color: #960C0C;


}

div .my-label{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: 0.05em;

    color: #2B1C1C;
}

.has-search .form-control {
    padding-left: 1.6rem;
    background: none !important;
    border: 0;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    /* width: 2.375rem; */
    height: 2.375rem;
    /* line-height: 2.375rem; */
    margin-top: 2px;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.searchOut{
    outline: none;
    /* border: none !important; */
    border-bottom: 1px solid rgba(43, 28, 28, 0.5) !important;
    border-top: none;
    border-left: none;
    border-radius: 0;
    border-right: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    width:180px;
    position: relative;
    bottom: 10px;
}

.off{
    color: rgba(43, 28, 28, 0.5)
}

.svgs{
    width: 75px;
}

.trContent{
    font-family: IBM Plex Sans;
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 23px;
/* display: flex; */
align-items: center;
letter-spacing: 0.02em;
height: 50px;
border-top: 1px solid lightgray;
color:rgba(43, 28, 28, 0.85);
}
/* th{
     padding-right: 100px; 
} */
td{
    text-transform: capitalize;
}

.export {
    cursor: pointer;
    color: #00A998;
    background: none;
    border:none;
    font-weight: 600;
    font-size: 14px;
    margin-right: -138px;
    margin-top: -18px;
    margin-left: -65px;
    z-index: 2;
    width: 85px;
}

.pagination {
    float: right;
    margin-right: 120px;
  }
  
  .pagination a {
    color: black;
    float: left;
    border:none;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    margin-right: 15px;
    margin-bottom: 10px;
      background: rgba(43, 28, 28, 0.05);
      border-radius: 4px;
  }

  .pagination a{
    color: black;
    float: left;
    border:none;
    padding: 8px 16px;
    text-decoration: none;
    float: right;
    margin-right: 5px;
  }
  
  .pagination a.active {
    background-color: #960C0C;
    padding: 10px 20px;
    color: white;
  }

  .sideBut{
    margin-bottom: 45px !important
  }

  th{
    margin:0.9rem 1.2rem 0.9rem 0 !important;
  }
  .bio-title{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 16px;
      color: #2B1C1C;
  }
  .bio-data{

      font-size: 13px;
      line-height: 18px
  }
  .more-box{
      /*margin-right: -20px !important;*/
      padding-bottom: 25px !important;
  }
  .question-info{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;

      /* identical to box height */
      display: flex;
      align-items: center;
      letter-spacing: 0.05em;

      color: #00A998;
  }
  .question-label{
      font-family: IBM Plex Sans;
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 21px;

      color: #000000;
  }
  .score-circle{
      border-radius: 50%;
      background: #960C0C;
  }