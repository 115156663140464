.date, .time {
    width: 200px;
    margin-bottom: 10px;
    background: #FFFFFF;
    margin-left: 15px !important;
    padding-bottom: 5px !important;
    border: none;
    border-radius: 5px;
}

.closeBox {
    cursor: pointer;
}

.MuiDialogTitle-root {
    padding-bottom: 0px !important;
}


.dialog-heading {
    font-family: IBM Plex Sans !important;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    font-weight: bold;
    padding-bottom: 0px !important;
    /* identical to box height, or 112% */
    letter-spacing: -0.02em;

}

.dialog-des {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 23px;

    color: #2B1C1C;
}
.ussd-des{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}


.dialog-main {
    /*margin-left: 76px !important;*/
    padding: 45px;
    border-radius: 16px !important;
}


.dialog-width {

    border-radius: 16px !important;
    /* .MuiDialog-paperWidthSm { */
    max-width: 450px;
    background-color: white !important;
    height: 200% !important;
    /* } */
}
.dialog-width-no-scroll{
    overflow-x: hidden;
    overflow-y: hidden;
}

.schedule-width {
    border-radius: 16px !important;
    /* .MuiDialog-paperWidthSm { */
    width: 500px !important;
    /*max-height: 60% !important;*/
    background-color: white !important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #2B1C1C !important;
    padding-top: 8px !important;


}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #2B1C1C !important;
    padding-top: 8px !important;


}


::placeholder {
    padding-top: 8px !important;
    color: #2B1C1C !important;
    padding-left: 5px !important;

}

.invite-textarea {

    width: 270px !important;
    margin-left: 15px !important;

    height: 100px !important;
    /*margin-top: 10px !important;*/
    border: 1px solid rgba(150, 12, 12, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #ffffff;
    resize: none;
    overflow: auto;
    margin-bottom: 50px !important;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modalbtn {
    background: #960C0C !important;
    border-radius: 6px !important;
    margin-top: 25px;
    /*padding-right: 30px;*/
    /*padding-left: 30px;*/
    width: 390px;
}


.adminid {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.01em;


}

.decisionRec {
    box-shadow: 0px 8px 12px rgba(108, 132, 120, 0.1);

}
/*.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box{*/
/*  height: 100px !important;*/
/*}*/

.decision-radio {

    margin-left: 80px !important;

}

.date-and-time-input-box {
    display: flex;
    border: 1px solid rgba(150, 12, 12, 0.5);
    max-width: 285px;
    margin-left: 2px;
    border-radius: 6px;
    align-items: center;
    margin-bottom: 15px;
}
.ussd-radio{
    margin-left: 110px !important;
}
.transfer-radio{
    margin-left: 57px !important;
}

.schedule-box {
    /*display: flex;*/
    border: 1.5px solid rgba(150, 12, 12, 0.5);
    width: 360px !important;
    /*margin-left: 2px;*/
    border-radius: 6px;
    align-items: center;
    margin-bottom: 20px;
    height: 250px;
    padding: 0px !important;

}

.schedule-mode {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    /* identical to box height, or 117% */
    display: flex;
    align-items: flex-end;

    color: #960C0C;


}

.schedule-box img {
    width: 30px;
    height: 30px;
    background: rgba(255, 126, 29, 0.15);
    border-radius: 8px;
    /*flex: 1;*/
}

.date-and-time-input-box .react-datepicker-wrapper {
    flex: 4;
}

.date-and-time-input-box img {
    width: 20px;
    height: 20px;
    flex: 8;
}


.send-radio {
    margin-left: 58px !important;
}


.textareaComment {
    width: 290px !important;
    margin-left: 15px !important;

    height: 80px !important;
    margin-top: 10px !important;
    border: 1px solid rgba(150, 12, 12, 0.5);
    box-sizing: border-box;
    border-radius: 6px;
    background-color: #ffffff;
    resize: none;
    overflow: auto;
    margin-bottom: 0px !important;
    padding-left: 30px;
    padding-top: 10px;
}

.decidetext {
    margin-top: 12px !important;
    margin-left: 10px;

}
.transLabel{
    margin-left: 0 !important;
}
.upload{
    height: 50px !important;
}
.uploadLabel{
    margin-left: 22px !important;
    margin-top: 10px !important;
    width: 100%;
    display: flex;
}
.upload img{
    margin-left: 85px !important;
}
.MuiInputBase-input::-webkit-input-placeholder{
    color: black !important;
    font-weight: bolder;
}
.amount{
    border: 1px solid rgba(150, 12, 12, 0.5);
    border-radius: 6px;
    width: 285px !important;
    margin-left: 15px !important;

    margin-bottom: 15px !important;

}
.MuiOutlinedInput-input {
    color: black !important;
}
.MuiInputLabel-formControl{
    margin-left: 13px !important;
    color: black !important;
}
.MuiOutlinedInput-root{
    height: 100% !important;
}
.paymentText {
    margin-top: 18px !important;
    margin-left: 10px;
}


.termsheading {

    margin-left: 32px !important;

}

.termscontent {

    margin-left: 52px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    font-family: IBM Plex Sans;
}

/*  */
.closeterms {

    padding: 13px 23px;
    margin-left: 30px;
    background: #FF2E2E;
    color: #FFFFFF;
    border: 1px solid rgba(43, 28, 28, 0.25);
    box-sizing: border-box;
    border-radius: 6px;
    /* margin-bottom: 20px !important; */

}

.cancelterms {

    padding: 7px 15px;
    color: #FF2E2E;
    background: #FFFFFF;
    border: 2px solid #FF2E2E;
    box-sizing: border-box;
    border-radius: 6px;
    /* margin-bottom: 20px !important; */

}

.schedule-status {

    padding: 7px 15px;
    color: #431288;;
    background: #D9D0E7;
    border: none !important;
    box-sizing: border-box;
    border-radius: 6px;
    margin-left: 80px;
    /* margin-bottom: 20px !important; */

}

.cancelsymbol {
    margin-top: 5px;
    font-size: 35px !important;
    float: left !important;
    font-weight: 100 !important;
    margin-left: 15px;
}

.cancelsym {
    margin-top: 5px;
    font-size: 35px !important;
    float: left !important;
    font-weight: 100 !important;
    margin-left: 0px !important;
}

.cancelsym:focus {
    color: #FF2E2E;
    cursor: pointer;
}

.cancelsymbol:hover {
    color: #FF2E2E;
    cursor: pointer;
}

.closetext {
    margin-left: 10px;
    margin-top: 15px;
}

.cancel {
    height: 40px;
    width: 40px;
}

.MuiTypography-body1 {

    color: #2B1C1C !important;
    font-family: IBM Plex Sans !important;
    font-style: normal !important;
    font-size: 15px !important;
    font-weight: 600 !important;
    line-height: 150% !important;
}

.MuiDialog-container {
    height: 90% !important;
    outline: 0;
    border-radius: 20px !important;
}

.scrollbar {
    overflow-y: scroll;
    margin-right: 50px
}

.scrollbar-primary {
    scrollbar-color: rgba(43, 28, 28, 0.15);
}

.scrollbar-primary::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.scrollbar-primary::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: rgba(43, 28, 28, 0.15);;
}


/*
    Info Session
*/

.info {
    color: #147DB9;
    font-weight: 600;
}

.info:hover {
    color: #147DB9;
}

.info-title {
    margin-left: 60px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -0.02em;
    color: #2B1C1C;
}

.info-context {
    margin-left: 60px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #2B1C1C;
}

.home-icon {
    width: 23px;
    height: 23px;
    margin-top: 10px;
}

.check-icon {
    width: 23px;
    height: 23px;
    margin-left: 50px;
    margin-bottom: 20px;
    padding-left: 4px;
}

.info-phy {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    margin-top: 50px;
    /* display: flex;
    align-items: flex-end; */
    color: #960C0C;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-between; */
}


.MuiDialogContent-root {
    padding: 6px 18px !important;

}
.inviteLabel{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.02em;

    color: #2B1C1C;
}
.inviteWord{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-left: 7px;
    color: #2B1C1C;
}
.inviteWordRed{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    margin-left: 7px;
    color: #960C0C;

}
.share{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-left: 7px;
    color: #2B1C1C;
}
.social{
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    margin-left: 7px;
    color: #960C0C !important;
    /*margin-right: 5px;*/
}
 .social-link{
    cursor: pointer;
    color: #960C0C !important;
}
@media (max-width: 859px) {

    .dialog-main{
        padding: 0;
        margin-right: 15px;
    }
    .date-and-time-input-box img{
        margin-right: 5px;
    }
}

