@media screen and (max-width: 600px) {
    .partners {
        display: none;
    }

    .patDiv {
        display: none;
    }
}

@media screen and (width: 768px) {
    .bac {
        /* max-width: 25.333333%!important; */
    }

    .partners {

    }
}

.bac {
    background: #FFF0CE;
}

.circle {
    background: white;
    height: 10vh;
    width: 10vh;
    border-radius: 50%;
    margin-top: 50px;
    margin-left: 65px;
    padding-right: 0px !important;
    padding-left: 0px !important;
}

.thumb {
    margin-left: 55px !important;
    /*margin-bottom: 12px!important;*/
    margin-top: 50px !important;
}

.box {
    /*padding: 50px;*/
    min-height: 72vh;
    overflow: hidden;
    top: 5px;
    padding-bottom: 15px;
}

.text-area {
    height: 30vh;

    /*background: white;*/
}

.submit-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    margin-left: 70px;
    margin-right: 70px;

    /*line-height: 60px;*/
    /* or 111% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    color: #960C0C;
}

.p-text {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: normal;
    margin-left: 70px;
    margin-right: 80px;

    margin-top: 30px;
    font-size: 13px;
    line-height: 175%;
    /* or 37px */

    letter-spacing: -0.02em;

    color: #2B1C1C;
}

.next-button {
    background: #FF2E2E;
    border: none;
    color: white;
    margin: 5.83% 46.88% 1.5% 20.62% !important;
    height: 52px;
    width: 180px
}

.circle2 {
    background: #FFF2C7 !important;
}

.newback {
    background: #F6F6F6 !important;
}

.rowl {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    background: white !important;
}

.side-new {
    background: white !important;
}

.prev-button {
    background: white;
    border-radius: 6px;
    /*padding: 13px;*/
    color: #FF2E2E;
    border: 2px solid #FF2E2E;
    margin-top: 15px;
    margin-left: 70px;
    height: 52px;
    width: 180px
}

.submit {
    background: #FF2E2E !important;
    /*border-radius: 6px;*/
    /*padding: 13px;*/
    /*border: none;*/
    color: white !important;
    /*margin-top: 70px;*/
}

.mt-md-5 {
    padding-bottom: 50px;
}

.back-to-dashboard {
    transition: all .5s ease;
}

.back-to-dashboard:hover {
    background: #FF2E2E;
    color: white;
}