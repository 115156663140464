.navBar {
    height: 100px;
}

.logo {
    padding: 20px;
}

.full-info {
    border-radius: 12px;
    background-color: #FFF2C7;
}

.summary-status {

}

.summary-second-div {;
}

.profile-card {
    background: #FFF2C7 !important;
    border-radius: 16px !important;
}
.student-border{
    border-bottom: 1px solid rgba(130, 130, 130, 0.15) !important;
    padding-bottom: 50px;
}

.fullInfoInner {
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 350px;
    height: 350px;
    /*margin-top: 20px;*/
}

.opacityBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    opacity: 0.5;
    background: #FFF2C7;
    z-index: 5;
    transition: all .2s ease-in-out;
}

.opacityBoxGone {
    visibility: hidden;
    opacity: 0;
}

.onScroll {
    opacity: 0.67;
}

.fullInfoInner::-webkit-scrollbar {
    background: transparent !important;
    width: 6px;
    height: 40px;
}

.fullInfoInner::-webkit-scrollbar-thumb {
    background: rgba(43, 28, 28, 0.5);
    max-height: 40px !important;
    height: 40px !important;
    border-radius: 12px;
}

